import React, {Fragment} from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import {inject, observer} from 'mobx-react';
import { get, set } from 'lodash-es';
import RichTextEditor from "./richtext/RichTextEditor";
import CharCounter from "./CharCounter";

@inject("uiStore")
@observer
class SyncedInput extends React.Component {

	constructor(props) {
		super(props);
		if(props.mandatory) {
			this.updateMandatoryStatus();
		}
	}

	componentDidUpdate() {
		const { mandatory } = this.props;
		if(mandatory) {
			this.updateMandatoryStatus();
		}
	}

	componentWillUnmount() {
		const { mandatory, source, id, uiStore } = this.props;
		if(mandatory) {
			uiStore.removeMandatoryFieldComponent(source, id);
		}
	}

	@autobind
	updateMandatoryStatus() {  // keep track of components that are mandatory
		const { source, id, uiStore } = this.props;
		let value = get(source, id, null);
		if(value) {
			uiStore.removeMandatoryFieldComponent(source, id);
		} else {
			uiStore.addMandatoryFieldComponent(source, id);
		}
	}

	@autobind
	handleChange(e) {
		const { source, id } = this.props;
		let val = (this.props.type === "checkbox") ? e.target.checked : e.target.value;
		if(this.props.type === "lax-number") {
			val = val.replace(/,/g, "."); // replace comma with period
		}
		if(this.props.updateSource !== false) {
			set(source, id, val);
		}
		if(this.props.onChangeCallback) {
			this.props.onChangeCallback(e);
		}
	}

	render() {
		const { source, id, rich = false, mandatory, onChangeCallback, uiStore, className, charCounter, charCounterMax, ...rest } = this.props;
		if(rest.type === "checkbox") {
			rest.checked = source[id];
		} else {
			rest.value = get(source, id, "");
		}
		rest.value = rest.value || ""; // Input doesn't like null values

		let _className = className;
		if(mandatory && !rest.value) {
			_className += " is-invalid";
		}

		if(rich) {
			return (
				<Fragment>
					{charCounter && <CharCounter source={source} id={id} charCounterMax={charCounterMax} placeholder={rest.placeholder} /> }
					<RichTextEditor name={id} id={id} className={_className} onChange={this.handleChange} stripPastedStyles={true} {...rest} />
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					{charCounter && <CharCounter source={source} id={id} charCounterMax={charCounterMax} placeholder={rest.placeholder} /> }
					<Input name={id} id={id} className={_className} onChange={this.handleChange} {...rest} />
				</Fragment>
			);
		}
	}
}

SyncedInput.propTypes = {
	onChangeCustom: PropTypes.func,
	id: PropTypes.string.isRequired,
	rich: PropTypes.bool,
	source: PropTypes.object.isRequired,
	mandatory: PropTypes.bool,
	charCounterMax: PropTypes.number,
	charCounter: PropTypes.bool,
	updateSource: PropTypes.bool
};

SyncedInput.defaultProps = {
	mandatory: false,
	charCounter: false,
	updateSource: true
};

export default SyncedInput;

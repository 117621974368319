import React from 'react';
import { inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import IngredientLine from './IngredientLine';

// Define the properties of the different sortables
const SortableIngredientLine = SortableElement(observer(({ isLast, line }) => (
	<IngredientLine isLast={isLast} line={line} />
)));

const SortableIngredients = SortableContainer(observer(({ items }) => (
	<div className="ingredientline-container">
		{items.map((il, i) => <SortableIngredientLine key={il.id} index={i} line={il} disabled={i === items.length - 1} isLast={i === items.length - 1} />)}
	</div>
)));


@inject("recipeStore")
@observer
class Ingredients extends React.Component {

	@autobind
	handleNewSortOrder({ oldIndex, newIndex }) {
		if(oldIndex !== newIndex) {
			this.props.recipeStore.currentRecipe.moveIngredientLine(oldIndex, newIndex);
		}
	}

	render() {
		const ingredientLines = this.props.recipeStore.currentRecipe.ingredientLines;
		return (<SortableIngredients
			lockToContainerEdges={true}
			lockAxis="y"
			items={ingredientLines}
			useDragHandle={true}
			onSortEnd={this.handleNewSortOrder}
		/>);
	}
}

export default Ingredients;